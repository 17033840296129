<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-start"
          >
            <b-form-group
              label="On/Off"
              label-for="on-off-status"
            >
              <b-form-radio-group
                v-model="query.appOnOff"
                :options="codes.onOff"
                value-field="code"
                text-field="label"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-start"
          >
            <b-form-group
              label="의사별/요양기관별"
              label-for="counting-group"
            >
              <b-form-radio-group
                v-model="query.countingGroup"
                :options="codes.countingGroups"
                value-field="code"
                text-field="label"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-start"
          >
            <b-form-group
              label="약품정보/설문조사"
              label-for="on-off-options"
            >
              <b-form-radio-group
                v-model="query.appOnOffOption"
                :options="codes.onOffOptions"
                value-field="code"
                text-field="label"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-datepicker
              v-model="query.startDate"
              class="el-def"
              placeholder="조회기간(Fr)"
            />
            <label class="ml-25 mr-50">~</label>
            <b-form-datepicker
              v-model="query.endDate"
              class="el-def"
              placeholder="조회기간(To)"
            />
          </b-col>
          <b-col
            md="6"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="btn-search"
              @click.prevent="fetchData()"
            >
              조회
            </b-button>
            <b-button
              variant="outline-dark"
              class="btn-search ml-1"
              @click.prevent="excelDownload()"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span>엑셀 다운로드</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-card>
      <b-table-simple
        bordered
        responsive
        sticky-header
        style="max-height: 65vh;"
      >
        <colgroup>
          <col style="width: 150px">
          <col
            v-for="dept in headers"
            :key="`col-${dept.medicalDepartment}`"
            style="width: 140px"
          >
        </colgroup>
        <b-thead head-variant="light">
          <b-tr>
            <b-th>일자</b-th>
            <b-th
              v-for="dept in headers"
              :key="`header-${dept.medicalDepartment}`"
            >
              {{ dept.medicalDepartmentName }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody v-if="onOffDatas.length > 0">
          <b-tr
            v-for="item in onOffDatas"
            :key="`date-${item.date}`"
          >
            <b-td>{{ item.date }}</b-td>
            <b-td
              v-for="dept in item.depts"
              :key="`dept-${dept.medicalDepartment}`"
            >
              {{ dept.sumCount | $numberFormatter }}
            </b-td>
          </b-tr>
        </b-tbody>

        <b-tbody v-else>
          <b-tr>
            <b-td>
              <div
                role="alert"
                aria-live="polite"
              >
                <div class="text-center my-2">
                  일치하는 데이터가 없습니다.
                </div>
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
  </div>
</template>

<script>
import _ from 'lodash'
import dayjs from 'dayjs'
import axios from '@axios'
import { onMounted, ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BTableSimple, BThead, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'
import ExcelJS from "exceljs"
import { saveAs } from "file-saver"

export default {
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
  },

  setup() {
    onMounted(() => {
      fetchCodes()
      fetchData()
    })

    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon

      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }

      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    const query = ref({
      startDate: dayjs().add(-7, 'day').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
      appOnOff: 1, // 활성
      countingGroup: 2, // 요양기관
      appOnOffOption: 1, // 약품
    })

    const codes = ref({
      onOff: [],
      countingGroups: [],
      onOffOptions: [],
    })

    const headers = ref([])
    const onOffDatas = ref([])

    const fetchCodes = () => {
      axios.get('/fa/statistics/onoff-codes')
        .then(rs => {
          const {
            appOnOff,
            appOnOffOption,
            countingGroup,
          } = rs.data

          codes.value.onOff = appOnOff
          codes.value.countingGroups = countingGroup
          codes.value.onOffOptions = appOnOffOption
        })
        .catch(() => {
          pushToast('danger', '코드 데이터를 불러오는데 실패하였습니다.')
        })
    }

    const fetchData = () => {
      const frDate = dayjs(query.value.startDate)
      const toDate = dayjs(query.value.endDate)
      if (Math.abs(frDate.diff(toDate, 'day')) > 15) {
        pushToast('warning', '조회 기간은 최대 15일을 넘을 수 없습니다.')
        return
      }

      axios.get('/fa/statistics/onoff', {
        params: query.value,
      })
        .then(rs => {
          const result = rs.data

          const depts = _(result).uniqBy('medicalDepartmentName').map(x => ({
            medicalDepartment: x.medicalDepartment,
            medicalDepartmentName: x.medicalDepartmentName,
            sumCount: 0,
            sortOrder: x.sortOrder,
          }))
            .orderBy(['sortOrder'], ['asc'])
            .value()

          const dateGroups = _(result).groupBy('date').map((item, key) => ({
            date: key,
            depts: _(item).unionBy(depts, 'medicalDepartment').orderBy(['sortOrder'], ['asc']).value(),
          }))
            .orderBy(['date'], ['desc'])
            .value()

          headers.value = depts
          onOffDatas.value = dateGroups
        })
        .catch(() => {
          pushToast('danger', '데이터 조회 중 오류가 발생하였습니다.')
        })
    }

    const excelDownload = async () => {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet("sheet1")

      const columns = [
        { header: "일자", key: "date", width: 20 },
      ]

      const depts = headers.value
      for (let i = 0; i < depts.length; i += 1) {
        columns.push({
          header: depts[i].medicalDepartmentName,
          key: `dept-${(depts[i].medicalDepartment || 999)}`,
          width: 20,
        })
      }

      worksheet.columns = columns

      const data = onOffDatas.value
      // eslint-disable-next-line array-callback-return
      data.map(e => {
        const record = {
          date: e.date,
        }
        for (let i = 0; i < e.depts.length; i += 1) {
          const dept = `dept-${(e.depts[i].medicalDepartment || 999)}`
          record[dept] = e.depts[i].sumCount
        }

        worksheet.addRow(record)
      })

      worksheet.eachRow({ includeEmpty: true }, (row, number) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (number === 1) {
            // eslint-disable-next-line no-param-reassign
            cell.font = {
              bold: true,
            }

            // eslint-disable-next-line no-param-reassign
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D3D3D3' },
            }
          }

          // eslint-disable-next-line no-param-reassign
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }

          // eslint-disable-next-line no-param-reassign
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'left',
          }
        })
      })

      const mimeType = {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }

      const buffer = await workbook.xlsx.writeBuffer()
      const blob = new Blob([buffer], mimeType)

      saveAs(blob, "On/Off 통계.xlsx")
    }

    return {
      query,
      codes,
      fetchCodes,
      fetchData,
      headers,
      onOffDatas,
      excelDownload,
    }
  },
}
</script>

<style scoped>
.table-bordered th, td {
  border: 1px solid #dee2e6;
}
</style>
